<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" sm="12" md="6" lg="6" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Kas/Bank <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select outlined flat dense background-color="white"></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Nomor # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field outlined flat dense background-color="white"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col cols="12" sm="12" md="6" lg="6" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>No Cek #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field outlined flat dense background-color="white"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tanggal <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="dateStart"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateStart" @input="menuStart = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col cols="12" class="py-0">
            <v-tabs vertical background-color="light-blue accent-3" dark class="px-0">
              <v-tab>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat color="px-2 py-0">
                  <v-row>
                    <v-col cols="5">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        placeholder="Find/Choose Account"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="7" align-self="start" class="text-right">
                      <p class="headline font-italic">
                        Deposit Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                      ></v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-row class="py-0">
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Catatan</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="6" lg="6" class="pb-0">
                      <v-textarea outlined flat rows="3" background-color="white"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Pemberi</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                      <v-textarea outlined flat rows="3" background-color="white"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="py-0">
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Branch</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                      <v-select outlined flat dense background-color="white"></v-select>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title>Nilai</v-card-title>
              <v-card-text class="text-right">
                <p>Rp. 0</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="amber darken-2">
              <v-icon large>
                mdi-printer
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

export default {
  //   mixins: [validationMixin],

  //   validations: {
  //     form: {
  //       accountNo: { required },
  //       name: { required }
  //     }
  //   },

  data: () => ({
    defaultForm: {
      month: null,
      year: new Date().getFullYear().toString(),
      type: null,
      note: "",
      analysName: "",
      items: [],
    },
    menuStart: false,
    dateStart: new Date().toISOString().substr(0, 10),
    form: {},
    headers: [
      {
        text: "Akun",
        value: "name",
        sortable: false,
        width: "20%",
        divider: true,
      },
      {
        text: "Nama Akun",
        value: "name",
        sortable: false,
        width: "60%",
        divider: true,
      },
      {
        text: "Nilai",
        value: "code",
        sortable: false,
        width: "20%",
        divider: true,
      },
    ],
  }),

  computed: {},

  watch: {
    form: {
      handler() {
        this.unlock = true;
      },
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key];
          if (input.includes("$")) return false;
          if (this.$v.form[input].$error) {
            this.$refs[input].focus();
            break;
          }
        }
      } else {
        console.log(this.form);
      }
    },
    clear() {
      this.$v.form.$reset();
      this.form = Object.assign({}, this.defaultForm);
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
